import type { SVGProps } from "react"

export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="23"
    viewBox="0 0 42 23"
    fill="none"
    {...props}
  >
    <path
      d="M34.2553 13.7038H37.234L42 6.95118H20.5532L17.3759 11.4198L15.5887 9.63235L22.4397 0H11.4184C5.16312 0 0 5.06443 0 11.4198C0 17.6759 5.06383 22.8396 11.4184 22.8396H27.9007L34.2553 13.7038ZM16.8794 5.06443L14.3972 8.54002L10.8227 5.06443H16.8794ZM3.67376 5.06443L14.8936 16.087L16.5816 13.7038H32.3688L30.6808 16.087H14.8936H3.67376V5.06443Z"
      fill="#FFFFF4"
    />
  </svg>
)
export default Logo
