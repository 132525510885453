"use client"

import React, { useEffect } from "react"
import Logo from "@/icons/Logo"
import { H2Med } from "@/styles/Type"
import clsx from "clsx"
import { API_URL } from "@/lib/api"
import { useChatContext } from "stream-chat-react"
import { StreamTypes } from "@/lib/chatClient"
import { Event } from "stream-chat"

interface AddDeviceClientProps {
  deviceData: { user_id: string; ott: string }
  onAddedSuccess: (e: any, isAddDevice: boolean) => void
  // eslint-disable-next-line react/require-default-props
  logoClassName?: string
}
const AddDeviceClient = React.memo(
  ({
    onAddedSuccess,
    deviceData,
    logoClassName = "",
  }: AddDeviceClientProps) => {
    const { client } = useChatContext<StreamTypes>()

    useEffect(() => {
      const handleChatEvent = async (event: Event<StreamTypes>) => {
        if (
          event.channel_id === `${deviceData?.user_id}_system_message_channel`
        ) {
          const parsedMessage = JSON.parse(event.message.text)
          if (
            parsedMessage?.payload?.actions?.WEB_APP_ACTION?.includes(
              "MOBILE_ONBOARDING_FINISHED"
            )
          ) {
            onAddedSuccess({ preventDefault: () => {} }, true)
          }
        }
      }
      client.on("notification.message_new", handleChatEvent)
      return () => {
        client.off("notification.message_new", handleChatEvent)
      }
    }, [client, onAddedSuccess, deviceData?.user_id])

    if (!deviceData?.user_id) {
      return null
    }

    return (
      <div className="h-screen w-full flex items-center flex-col justify-between text-white/30 text-2xl">
        <video
          className={clsx("fixed inset-0 h-full w-full object-cover")}
          src="/assets/videos/bg_video.mp4"
          autoPlay
          playsInline
          loop
          muted
        />
        <div className={`mr-auto ml-5 mt-5 ${logoClassName}`}>
          <Logo className="relative" />
        </div>
        <div className="flex w-full items-center justify-around">
          <div className="flex flex-col w-[320px] text-center">
            <H2Med className="relative text-cream mb-3">
              Secure your account
            </H2Med>
            <H2Med className="relative mb-5">
              Verify your Lumina ID details on the Lumina Membership app{" "}
            </H2Med>
            <H2Med className="relative !text-[12px] text-white/40">
              Scan the QR code using your iOS device
            </H2Med>
          </div>
          <div className="">
            <img
              width={730}
              height={730}
              className="relative w-[220px] h-[220px] rounded-[20px]"
              src={`${API_URL}/api/app/generate/qr/${deviceData?.user_id}/`}
              alt=""
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-[12px] mb-10">
          <H2Med className="relative text-center !text-[14px]">
            Continue the verification process on your iOS device
          </H2Med>
          <H2Med className="relative text-center !text-[14px] text-white/50">
            Don’t have and iOS device? Contact us
          </H2Med>
        </div>
      </div>
    )
  }
)

AddDeviceClient.displayName = "AddDeviceClient"

export default AddDeviceClient
